<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 367.2">
    <title>position</title>
    <g id="mac">
      <rect x="210.8" y="312.3" width="91.4" height="50.06" fill="#b5b9ba" />
      <rect x="154.2" y="362.3" width="204.6" height="4.9" fill="#8a9093" />
      <rect x="210.8" y="312.3" width="91.4" height="18.5" fill="#929699" />
      <g>
        <rect
          x="0.5"
          y="0.5"
          width="512"
          height="311.77"
          rx="22.5"
          ry="22.5"
          fill="#323233"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <path
          d="M512,366a22.5,22.5,0,0,1-22.5,22.5H22.5c-12.4,0,467-311.8,467-311.8A22.5,22.5,0,0,1,512,99.2Z"
          transform="translate(0.5 -76.2)"
          fill="#2d2d2d"
        />
      </g>
      <g>
        <rect x="22.2" y="21.1" width="468.6" height="268.33" :fill="bgFill" />
        <path
          d="M489.8,97.9V365.2H22.2V97.9H489.8m1-1H21.2V366.2H490.8V96.9Z"
          transform="translate(0.5 -76.2)"
          fill="#231f20"
        />
      </g>
      <path
        d="M260.9,87.6a4.9,4.9,0,1,1-4.9-4.9A4.9,4.9,0,0,1,260.9,87.6Z"
        transform="translate(0.5 -76.2)"
        fill="#323031"
      />
      <path
        d="M258.2,87.6a2.2,2.2,0,0,1-4.4,0,2.2,2.2,0,0,1,4.4,0Z"
        transform="translate(0.5 -76.2)"
        fill="#231f20"
      />
    </g>
    <g id="positions">
      <rect
        v-for="choice in choices"
        :key="choice"
        class="mosaic"
        :fill="isNano && choice === 4 && !isV2Teaser ? bgFill : 'white'"
        :class="mosaicClass(choice)"
        :x="xPos(choice)"
        :y="yPos(choice)"
        :width="widthValue"
        height="89.1"
        stroke="#231f20"
        stroke-miterlimit="10"
        @click="selectMosaic(choice)"
      />
    </g>
  </svg>
</template>
<script>
  import { mapMutations, mapGetters, mapState } from 'vuex';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import itemMixin from '../../mixins/item';

  const TAB_ASSOC = {
    1: 'top-left',
    2: 'top-center',
    3: 'top-right',
    4: 'left-center',
    6: 'right-center',
    7: 'bottom-left',
    8: 'bottom-center',
    9: 'bottom-right',
  };

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      bgFill: { type: String, default: 'white' },
    },
    data() {
      return {
        choices: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        widthValue: 155.8,
        oldNanoPosition: 1,
        oldSidebarPosition: 5,
        TEMPLATE_FEATURES,
      };
    },
    computed: {
      ...mapState(['selectedPage']),
      ...mapGetters([
        'isNano',
        'isTabSelected',
        'isTeaserPage',
        'isSidebarOnMobile',
        'isFullHeightSidebar',
        'hasTemplateFeature',
      ]),
      isV2Teaser() {
        return (
          this.hasTemplateFeature(this.TEMPLATE_FEATURES.NEW_TEASER) &&
          this.selectedPage &&
          this.selectedPage.isTeaser
        );
      },
      isTeaser() {
        return this.isTabSelected || this.isTeaserPage;
      },
      selectedMosaic() {
        let selectedId = this.getValueOf(this.property, 5);
        if (this.isTeaser) {
          const selectedValue = this.getValueOf(this.property, 5);
          selectedId = 1;
          for (const [index, value] of Object.entries(TAB_ASSOC)) {
            if (value === selectedValue) {
              selectedId = parseInt(index, 10);
            }
          }
          return selectedId;
        }
        if (this.isNano) {
          if (selectedId === 1 || selectedId === 7) {
            return selectedId;
          }
          return 1;
        }
        return selectedId || 5;
      },
    },
    watch: {
      isNano: {
        handler(after) {
          if (!this.isTeaser) {
            if (after) {
              this.oldSidebarPosition = this.getValueOf(this.property);
              this.setValueOf(this.property, this.oldNanoPosition);
              this.setNanoSettings();
            } else {
              this.oldNanoPosition = this.getValueOf(this.property);
              this.setValueOf(this.property, this.oldSidebarPosition);
              this.choices = [1, 2, 3, 4, 5, 6, 7, 8, 9];
              this.widthValue = 155.8;
            }
          }
        },
      },
    },
    mounted() {
      if (this.isNano && !this.isTeaser) {
        this.setNanoSettings();
      }

      if (this.isTeaser) {
        this.choices = [1, 3, 7, 9];
        this.selectMosaic(this.selectedMosaic);
      }

      if (this.isV2Teaser) {
        this.choices = [1, 2, 3, 4, 6, 7, 8, 9];
        this.selectMosaic(this.selectedMosaic);
      }
    },
    methods: {
      ...mapMutations(['updateStyle']),
      selectMosaic(value) {
        window.om.bus.$emit('userInputChange', { property: this.property, value });
        if (this.isTeaser) return this.setValueOf(this.property, TAB_ASSOC[value]);
        if (this.isNano && value === 4) return;
        this.setValueOf(this.property, value);
      },
      mosaicClass(index) {
        const result = {};
        if (this.selectedMosaic === index) result.selected = true;
        if (this.isNano && index === 4 && !this.isV2Teaser) result.disabled = true;
        if (this.isTeaser && !this.isV2Teaser && [2, 4, 5, 6, 8].includes(index))
          result.disabled = true;
        if (this.isV2Teaser && [5].includes(index)) result.disabled = true;
        if ((this.isFullHeightSidebar || this.isSidebarOnMobile) && [5].includes(index))
          result.disabled = true;
        return result;
      },
      xPos(index) {
        const remainder = (index - 1) % 3;
        let result = 22.8;
        if (remainder === 1) {
          result = 178.6;
        } else if (remainder === 2) {
          result = 334.4;
        }
        return result;
      },
      yPos(index) {
        let result = 21.7;
        if (index > 6) {
          result = 199.9;
        } else if (index > 3) {
          result = 110.8;
        }
        return result;
      },
      setNanoSettings() {
        this.choices = [1, 4, 7];
        this.widthValue = 467.4;
        const value = this.getValueOf(this.property);
        if (value === 1 || value === 7) {
          this.setValueOf(this.property, value);
        } else {
          this.setValueOf(this.property, 1);
        }
      },
    },
  };
</script>
